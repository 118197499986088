/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
/* import pro solid icons */
import {
  faTurnDown,
  faPenToSquare,
  faArrowUpRightFromSquare,
  faOption,
  faStore,
  faBox,
  faListTree,
  faListDropdown,
  faGears,
  faFire,
  faCoins,
  faConveyorBelt,
  faUser,
  faGear,
  faCoin,
  faUsers,
  faHouse,
  faSpinner,
  faLock,
  faLockOpen,
  faCheck,
  faSquareExclamation,
  faChevronDown,
  faFilter,
  faChevronUp,
  faCircleCheck,
  faCircleXmark,
  faXmark,
  faChevronsRight,
  faArrowRight,
  faArrowLeft,
  faPlus,
  faCircleInfo,
  faGift,
  faAddressBook,
  faImagesUser,
  faUsersGear,
  faAddressCard,
  faFloppyDisk,
  faMessageExclamation,
  faSackDollar,
  faPenCircle,
  faMoneyBills,
  faCalendarCircleUser,
  faEllipsisVertical,
  faCircleEllipsisVertical,
  faChevronRight,
  faSpinnerThird,
  faCirclePlus,
  faMagnifyingGlass,
  faSlidersUp,
  faUpRight,
  faChartLine,
  faUserMinus,
  faHandHoldingDollar,
  faGifts,
  faTrash,
  faSort,
  faUserSecret,
  faCircleExclamation,
  faRepeat,
  faRightFromBracket,
  faClockRotateLeft,
  faArrowDown,
  faUserLock,
  faFolder,
} from '@fortawesome/pro-solid-svg-icons';
/* import free brands icons */
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
/* add icons to the library */
library.add(
  faTurnDown,
  faPenToSquare,
  faArrowUpRightFromSquare,
  faOption,
  faStore,
  faBox,
  faListTree,
  faListDropdown,
  faGears,
  faFire,
  faCoins,
  faConveyorBelt,
  faUser,
  faGear,
  faCoin,
  faUsers,
  faHouse,
  faSpinner,
  faLock,
  faLockOpen,
  faCheck,
  faGoogle,
  faSquareExclamation,
  faChevronDown,
  faFilter,
  faChevronUp,
  faCircleCheck,
  faCircleXmark,
  faXmark,
  faChevronsRight,
  faArrowRight,
  faArrowLeft,
  faPlus,
  faCircleInfo,
  faGift,
  faAddressBook,
  faImagesUser,
  faUsersGear,
  faAddressCard,
  faFloppyDisk,
  faMessageExclamation,
  faSackDollar,
  faPenCircle,
  faMoneyBills,
  faCalendarCircleUser,
  faEllipsisVertical,
  faCircleEllipsisVertical,
  faStore,
  faListTree,
  faChevronsRight,
  faChevronRight,
  faSpinnerThird,
  faCirclePlus,
  faMagnifyingGlass,
  faSlidersUp,
  faUpRight,
  faChartLine,
  faUserMinus,
  faHandHoldingDollar,
  faGifts,
  faTrash,
  faSort,
  faUserSecret,
  faCircleExclamation,
  faRepeat,
  faRightFromBracket,
  faClockRotateLeft,
  faArrowDown,
  faUserLock,
  faFolder,
);
/* export font awesome icons */
export default FontAwesomeIcon;
