/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/vue';
import store from '@/store';

import { fetchAPI, fetchLog } from '@/djangoAPI';
import { Cookies } from '@/cookies';

import {
  getAllBrand,
  getAllChannel,
  getAllClientData,
  getAllEventRules,
  getAllFidStatus,
  getAllStore,
  getAllTechnicalData,
  getEngine,
  getAllTechnicalDataType,
  getAllExtraInfo,
  getAllConstraint,
} from '@/store/actions';

export const UserSession = {
  loginFirst: (token, tokenDuration = 1) => {
    Cookies.set('UAT', token, tokenDuration);
    UserSession.initSession();
  },
  setUserAccessToken: (token) => {
    /**
     * Set token in djangoAPI instance
     */
    store.state.session.userAccessToken = token;
    fetchAPI.defaults.headers.Authorization = `Bearer ${token}`;
  },
  logoutSession: (status = 0) => {
    /**
     * Logout session
     */
    if (status === 401) console.log('logout');
    Cookies.delete('UAT');
    location.reload();
  },
  initSession: () => {
    /**
     * Init session
     */
    if (Cookies.get('UAT')) {
      // Check if the user_access_token is valid
      store.state.session.inProgress = true;
      // Set user_access_token
      UserSession.setUserAccessToken(Cookies.get('UAT'));
      // Fetch user profile
      fetchAPI.get(`/users/get_user/?username=${Cookies.get('username')}`, {}).then(({
        first_name, last_name, email, is_superuser, id,
      }) => {
        store.state.session.firstName = first_name || '';
        store.state.session.lastName = last_name || '';
        store.state.session.email = email;
        if (is_superuser) {
          store.state.session.isAdmin = true;
          store.state.session.permission = {
            id: 50,
            title: 'Administrateur',
          };
        } else {
          store.state.session.isAdmin = false;
          store.state.session.permission = {
            id: 1,
            title: 'Client',
          };
        }
        store.state.session.isAdmin = is_superuser;
        store.state.session.userID = id;
        // Set Sentry user context
        Sentry.setUser({
          email,
          token: Cookies.get('UAT'),
        });
      }).finally(() => {
        Promise.all([
          getAllStore(store),
          getAllChannel(store),
          getAllFidStatus(store),
          getAllEventRules(store),
          getAllBrand(store),
          getAllClientData(store),
          getAllTechnicalData(store),
          getEngine(store),
          getAllTechnicalDataType(store),
          getAllConstraint(store),
          getAllExtraInfo(store),
        ]).finally(() => {
          // set log api key
          fetchLog.defaults.headers['x-api-key'] = store.state.engine.log_s3_apikey;
          store.state.session.inProgress = false;
        });
      });
    }
  },
};
