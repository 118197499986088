import { createStore, createLogger } from 'vuex';
import * as getters from './getters';
import mutations from './mutations';
import * as actions from './actions';

const state = {
  notificationParams: {
    title: '',
    type: '',
    description: '',
  },
  notificationDisplay: false,
  config: {
    clientLogo: require('@/../public/img/logo/lineup7.png'),
    clientLogoWhite: require('@/../public/img/logo/lineup7_white.png'),
    clientName: 'LineUP 7',
    usersOwnerName: 'LineUP 7',
    loginBySSOActivated: true,
  },
  session: {
    inProgress: false,
    userAccessToken: undefined,
    firstName: '',
    lastName: '',
    isAdmin: false,
    userID: null,
    permission: {
      id: 50,
      title: 'Administrateur',
    },
  },
  store: [],
  channel: [],
  eventRules: [],
  brand: [],
  clientData: [],
  technicalData: [],
  technicalDataType: [],
  constraint: [],
  extraInfo: [],
  fidStatus: [],
  engine: null,
  // Contains data required for other components
  // For example initialData, is the initial data before any transformation by an API request
  // Any component can use it to notify the initial state of the data.
  data: {
    initialData: null,
  },
};

export default createStore({
  state,
  getters,
  mutations,
  actions,
  plugins: config.NODE_ENV || process.env.NODE_ENV !== 'production'
    ? []
    : [],
});
