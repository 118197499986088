export default {
  SET_Store(state, items) {
    state.store = items;
  },
  SET_Channel(state, items) {
    state.channel = items;
  },
  SET_Brand(state, items) {
    state.brand = items;
  },
  SET_EventRules(state, items) {
    state.eventRules = items;
  },
  SET_ClientData(state, items) {
    state.clientData = items;
  },
  SET_technicalData(state, items) {
    state.technicalData = items;
  },
  SET_technicalDataType(state, items) {
    state.technicalDataType = items;
  },
  SET_notificationParams(state, items) {
    state.notificationParams = items;
  },
  SET_notificationDisplay(state, items) {
    state.notificationDisplay = items;
  },
  SET_engine(state, items) {
    state.engine = items;
  },
  SET_constraint(state, items) {
    state.constraint = items;
  },
  SET_extraInfo(state, items) {
    state.extraInfo = items;
  },
  SET_fidStatus(state, items) {
    state.fidStatus = items;
  },
};
